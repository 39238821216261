<template>
  <form @submit.prevent="saveDocument" class="relative">
     <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex flex-col items-center justify-center space-y-4 z-20"
    >
      <Spinner :color="'text-black'" :size="10" />
      <div class="mb-5 w-full" v-if="isUploading">
        <!-- <p class="font-bold text-lg mb-2">Uploading image {{ image.name ? image.name : '' }}</p> -->
        <ProgressBar :progress="uploadProgress" />
      </div>
    </div>
   
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Document Name</label>
      <input
        class="bge-input bge-input-spacing w-full rounded"
        v-model="document.name"
        placeholder="Document Name..."
        type="text"
      />
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Document Description</label>
      <textarea
        v-model="document.description"
        class="bge-input bge-input-spacing w-full rounded"
        placeholder="Document Description..."
        rows="10"
      ></textarea>
    </div>
<FileUpload v-if="formType == 'New'" :file.sync="document.file" />
    <div class="relative flex justify-end mt-5">
      <div
        v-show="!canSubmitForm"
        class="opacity-50 bg-white absolute inset-0"
      ></div>
      <button
        :disabled="isBusy || !canSubmitForm"
        type="submit"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </div>

    
  </form>
</template>

<script>

const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");
const FileUpload = () => import("@/components/FileUpload.vue");
const ProgressBar = () => import("@/components/ProgressBar.vue");

export default {
  name: "AddEditDocument",
  components: {
     Spinner,
    Toggle,
    FileUpload,
    ProgressBar,
  },
  props: {
    document: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      state: "Create",
      formType: "New",
      isBusy: false,
      isUploading: false,
      uploadProgress: 0,
    };
  },
  mounted() {
    if (this.document.document_id) {
      this.state = "Edit";
    }
  },
  methods: {
    onDropFiles: function(e) {
      this.handleDocuments(e.dataTransfer.files);
    },
    onFilesChange: function(e) {
      this.handleDocuments(e.target.files);
    },
    handleDocuments: function(fileList) {
      if (fileList.length >= 1) {
        this.document.file = fileList[0];
        this.$forceUpdate();
      }
    },
    saveDocument: function() {
      
      this.$emit("complete", this.document);
    }
  },
   computed: {
    canSubmitForm() {
      if (this.document.document_id) {
        return (
          this.document.description &&
          this.document.name 
        );
      } else {
        return (
          this.document.file &&
          this.document.description &&
          this.document.name
        );
      }
    },
  },
};
</script>